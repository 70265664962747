export const formatToRequestJson = (game) => {
  return {
    name: game.name,
    alternativeName: game.alternativeName,
    tag: game.tag,
    description: game.description,
    website: game.website,
    cover: game.cover,
    headerImage: game.headerImage,
    album: game.album,
    guide: game.guide,
    validGameId: game.validGameId,
    serieId: game.serieId,
    esrbId: game.esrbId,
    pegiAgeId: game.pegiAgeId,
    genres: game.genres?.map(genre => genre),
    pegies: game.pegies?.map(pegi => pegi),
    subgenres: game.subgenres?.map(subgenre => subgenre),
    platforms: game.platforms.map((gamePlatform) => {
      return {
        id: gamePlatform.id,
        platformId: gamePlatform.platformId,
        weight: gamePlatform.weight,
        devCompanyId: gamePlatform.devCompanyId ?? null,
        editorCompanyId: gamePlatform.editorCompanyId ?? null,
        cover: gamePlatform.cover,
        score: {
          score: gamePlatform.score,
          scoreOnProgress: gamePlatform.scoreOnProgress
        },
        launchDate: {
          es: gamePlatform.launchDateES,
          us: gamePlatform.launchDateUS,
          jp: gamePlatform.launchDateJP
        },
        metaCritic: {
          sent: gamePlatform.metaCriticSent,
          text: gamePlatform.metaCriticText
        },
        detail: {
          maxPlayersOnline: gamePlatform.maxPlayersOnline,
          maxPlayersOffline: gamePlatform.maxPlayersOffline,
          estimatedDuration: gamePlatform.estimatedDuration,
          saveGameSize: gamePlatform.saveGameUnits,
          saveGameUnits: 'MBs',
          minimumSystem: gamePlatform.minimumSystem,
          recommendedSystem: gamePlatform.recommendedSystem,
          isCancelled: gamePlatform.isCancelled,
          is3DRequired: gamePlatform.is3DRequired
        },
        options: {
          voicesLanguage: gamePlatform.voicesLanguage,
          textLanguage: gamePlatform.textLanguage,
          manualLanguage: gamePlatform.manualLanguage,
          gameModes: gamePlatform.gameModes,
          videoModes: gamePlatform.videoModes,
          driverApis: gamePlatform.driverApis,
          soundsStandards: gamePlatform.soundsStandards,
          multiplayer: gamePlatform.multiplayer,
          multiplayerModes: gamePlatform.multiplayerModes,
          storageMedia: gamePlatform.storageMedia,
          inputDevices: gamePlatform.inputDevices,
          misc: gamePlatform.misc
        }
      }
    })
  }
}

export const formatToResponseJson = (game) => {
  return {
    name: game.name,
    alternativeName: game.alternativeName,
    tag: game.tag,
    description: game.description,
    website: game.website,
    cover: game.cover,
    headerImage: game.headerImage,
    album: game.album,
    guide: game.guide,
    validGameId: game.validGameId,
    serieId: game.serie?.id || null,
    esrbId: game.esrb?.id || null,
    pegiAgeId: game.pegiAge?.id | null,
    genres: game.genres?.map(genre => genre.id),
    pegies: game.pegies?.map(pegi => pegi.id),
    subgenres: game.subgenres?.map(subgenre => subgenre.id),
    platforms: game.platforms.map((gamePlatform) => {
      return {
        id: gamePlatform.id,
        platformId: gamePlatform.platform.id,
        name: gamePlatform.platform.name,
        weight: gamePlatform.weight,
        devCompanyId: gamePlatform?.devCompany?.id,
        editorCompanyId: gamePlatform?.editorCompany?.id,
        cover: gamePlatform.cover,
        score: gamePlatform?.score?.score,
        scoreOnProgress: gamePlatform.score.scoreOnProgress,
        launchDateES: gamePlatform.launchDate.es,
        launchDateUS: gamePlatform.launchDate.us,
        launchDateJP: gamePlatform.launchDate.jp,
        metaCriticText: gamePlatform.metaCritic.text,
        metaCriticSent: gamePlatform.metaCritic.sent,
        maxPlayersOnline: gamePlatform.detail.maxPlayersOnline,
        maxPlayersOffline: gamePlatform.detail.maxPlayersOffline,
        estimatedDuration: gamePlatform.detail.estimatedDuration,
        saveGameUnits: gamePlatform.detail.saveGameSize ? `${gamePlatform.detail.saveGameSize} ${gamePlatform.detail.saveGameUnits}` : null,
        minimumSystem: gamePlatform.detail.minimumSystem,
        recommendedSystem: gamePlatform.detail.recommendedSystem,
        isCancelled: gamePlatform.detail.isCancelled,
        is3DRequired: gamePlatform.detail.is3DRequired,
        voicesLanguage: gamePlatform.options.voicesLanguage,
        textLanguage: gamePlatform.options.textLanguage,
        manualLanguage: gamePlatform.options.manualLanguage,
        gameModes: gamePlatform.options.gameModes,
        videoModes: gamePlatform.options.videoModes,
        driverApis: gamePlatform.options.driverApis,
        soundsStandards: gamePlatform.options.soundsStandards,
        multiplayer: gamePlatform.options.multiplayer,
        multiplayerModes: gamePlatform.options.multiplayerModes,
        storageMedia: gamePlatform.options.storageMedia,
        inputDevices: gamePlatform.options.inputDevices,
        misc: gamePlatform.options.misc
      }
    })
  }
}
